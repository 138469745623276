import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../../pages/home/Home";
import Login from "../../pages/auth/Login";
import NotFound from "../../pages/NotFound";
import Profile from "../../pages/account/Profile";
import PhotoGallery from "../../pages/account/PhotoGallery";
import MenuPage from "../../pages/account/MenuPage";
import ProfileEdit from "../../pages/account/ProfileEdit";
import ProtectedRoute from "./ProtectedRoute";
import NavigationBar from "./NavigationBar";
import Restaurants from "../../pages/restaurants/Restaurants";
import KeysAllocation from "../../pages/account/KeysAllocation";
import MemberDetails from "../../pages/members/MemberDetails";
import Members from "../../pages/members/Members";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import PlatformPage from "../../pages/account/PlatformPage";
import Reservations from "../../pages/reservations/Reservations";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/restaurants",
    element: <Restaurants />,
  },
  {
    path: "/restaurants/:id",
    element: <Profile />,
  },
  {
    path: "/restaurants/:id/edit",
    element: <ProfileEdit />,
  },
  {
    path: "/restaurants/:id/photo-gallery",
    element: <PhotoGallery />,
  },
  {
    path: "/restaurants/:id/menu",
    element: <MenuPage />,
  },
  {
    path: "/restaurants/:id/keys",
    element: <KeysAllocation />,
  },
  {
    path: "/restaurants/:id/reservations",
    element: <Reservations />,
  },
  {
    path: "/restaurants/:id/connect-platform/:platform",
    element: <PlatformPage />,
  },
  {
    path: "/members",
    element: <Members />,
  },
  {
    path: "/members/:id",
    element: <MemberDetails />,
  },
];
export function ApplicationRouter() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<ProtectedRoute children={route.element} />}
          />
        ))}

        <Route element={<NotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  );
}
