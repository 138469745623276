import { gql } from "@apollo/client";

export const DUMMY_QUERY = gql`
  {
    dummy
  }
`;

export const GET_RESTAURANT = gql`
  query Restaurant {
    restaurant {
      uuid
      name
      notes
      seatCount
      cuisine
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      city {
        name
        location
        country
      }
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
    }
  }
`;

export const GET_RESTAURANTS = gql`
  query Restaurants(
    $city: String!
    $longitude: String
    $latitude: String
    $radiusInKm: Int
    $limit: Int
    $offset: Int
  ) {
    restaurants(
      location: {
        city: $city
        radiusInKm: $radiusInKm
        longitude: $longitude
        latitude: $latitude
      }
      options: { limit: $limit, offset: $offset }
    ) {
      items {
        uuid
        name
        notes
        seatCount
        cuisine
        details {
          arrivalPolicy
          description
          generalPolicy
          cancellationPolicy
        }
        city {
          name
          location
          country
        }
        contact {
          name
          email
          phone
          website
          instagram
        }
        location {
          longitude
          latitude
        }
      }
      count
    }
  }
`;

export const GET_MENU = gql`
  query Menu($uuid: String!) {
    menu(uuid: $uuid) {
      uuid
      categories {
        uuid
        title
        items {
          uuid
          name
          order
          description
          price {
            amount
            currency
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MANAGER = gql`
  query Manager {
    manager {
      uuid
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESERVATIONS = gql`
  query Reservations($uuid: String!, $from: String, $to: String) {
    reservations(uuid: $uuid, search: { from: $from, to: $to }) {
      items {
        uuid
        table {
          label
          type
          location
          recommendedPartySize
          maxPartySize
          minPartySize
        }
        date {
          start
          end
        }
        status
        member {
          uuid
          membershipId
          allergies
          restrictions
          bio
          joinDate
          birthDate
          profilePicture
          username
          firstName
          lastName
          preferredName
          email
          phone
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_GALLERY = gql`
  query Gallery($uuid: String!) {
    gallery(uuid: $uuid) {
      uuid
      primary {
        uuid
        data
        type
        alt
        createdAt
        updatedAt
      }
      items {
        uuid
        image {
          uuid
          data
          type
          alt
          createdAt
          updatedAt
        }
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_RESY_VENU = gql`
  query ResyVenu($uuid: String!) {
    resyVenue(uuid: $uuid) {
      id
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_VENUES = gql`
  query ResyAvailableVenues($uuid: String!) {
    resyAvailableVenues(uuid: $uuid) {
      id
      name
    }
  }
`;

export const GET_RESY_TAGS = gql`
  query ResyTags($uuid: String!) {
    resyTags(uuid: $uuid) {
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_TAGS = gql`
  query ResyAvailableVenues($uuid: String!) {
    resyAvailableTags(uuid: $uuid) {
      name
    }
  }
`;

export const GET_RESY_STATUS = gql`
  query ResyStatus($uuid: String!) {
    resyStatus(uuid: $uuid)
  }
`;

export const GET_RESY_VALID = gql`
  query ResyValid($uuid: String!) {
    resyValid(uuid: $uuid)
  }
`;

export const GET_MEMBERS = gql`
  query Members($limit: Int, $offset: Int) {
    members(limit: $limit, offset: $offset) {
      items {
        uuid
        membershipId
        allergies
        restrictions
        note
        bio
        joinDate
        birthDate
        profilePicture
        username
        firstName
        lastName
        preferredName
        email
        phone
        favouriteRestaurants {
          uuid
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;
