import { gql } from "@apollo/client";

export const ADD_RESTAURANT = gql`
  mutation AddRestaurant(
    $name: String!
    $notes: String!
    $seatCount: Int!
    $description: String!
    $cuisine: [String]!
    $website: String!
    $cityName: String!
    $cityLocation: String!
    $country: String!
    $contactEmail: String!
    $contactName: String!
    $contactPhone: String!
    $managerFirstName: String!
    $managerLastName: String!
    $managerEmail: String!
    $managerPhone: String!
    $managerBirthDate: String!
    $arrivalPolicy: String!
    $generalPolicy: String!
    $instagram: String!
    $cancellationPolicy: String!
  ) {
    addRestaurant(
      properties: {
        name: $name
        notes: $notes
        seatCount: $seatCount
        description: $description
        cityName: $cityName
        instagram: $instagram
        cityLocation: $cityLocation
        country: $country
        cuisine: $cuisine
        website: $website
        managerFirstName: $managerFirstName
        managerLastName: $managerLastName
        managerEmail: $managerEmail
        managerPhone: $managerPhone
        managerBirthDate: $managerBirthDate
        contactEmail: $contactEmail
        contactName: $contactName
        contactPhone: $contactPhone
        arrivalPolicy: $arrivalPolicy
        generalPolicy: $generalPolicy
        cancellationPolicy: $cancellationPolicy
      }
    ) {
      uuid
      name
      notes
      seatCount
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      city {
        name
        location
        country
      }
      cuisine
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($uuid: String!, $title: String!) {
    createCategory(uuid: $uuid, title: $title) {
      uuid
      title
      items {
        uuid
        name
        order
        description
        price {
          amount
          currency
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CHANGE_CATEGORY_TITLE = gql`
  mutation ChangeCategoryTitle($uuid: String!, $title: String!) {
    changeCategoryTitle(uuid: $uuid, title: $title) {
      uuid
      title
      items {
        uuid
        name
        order
        description
        price {
          amount
          currency
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($uuid: String!) {
    removeCategory(uuid: $uuid)
  }
`;

export const CREATE_MENU_ITEM = gql`
  mutation CreateMenuItem(
    $categoryUUID: String!
    $name: String!
    $order: Int!
    $price: Float!
    $currency: String!
    $description: String!
  ) {
    createMenuItem(
      categoryUUID: $categoryUUID
      item: {
        name: $name
        order: $order
        price: $price
        currency: $currency
        description: $description
      }
    ) {
      uuid
      name
      order
      description
      price {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_MENU_ITEM = gql`
  mutation UpdateMenuItem(
    $uuid: String!
    $name: String!
    $order: Int!
    $price: Float!
    $currency: String!
    $description: String!
  ) {
    updateMenuItem(
      uuid: $uuid
      item: {
        name: $name
        order: $order
        price: $price
        currency: $currency
        description: $description
      }
    ) {
      uuid
      name
      order
      description
      price {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_MENU_ITEM = gql`
  mutation RemoveMenuItem($uuid: String!) {
    removeMenuItem(uuid: $uuid)
  }
`;

export const UPDATE_GALLERY_IMAGE_ORDER = gql`
  mutation UpdateGalleryImageOrder(
    $imageUUID: String!
    $uuid: String!
    $order: Int!
  ) {
    updateGalleryImageOrder(imageUUID: $imageUUID, uuid: $uuid, order: $order)
  }
`;

export const SET_RESTAURANT_PRIMARY_IMAGE = gql`
  mutation SetRestaurantPrimaryImage(
    $uuid: String!
    $data: String!
    $alt: String!
    $type: String!
  ) {
    setRestaurantPrimaryImage(
      uuid: $uuid
      image: { data: $data, alt: $alt, type: $type }
    ) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const ADD_IMAGE_TO_GALLERY = gql`
  mutation AddImageToGallery(
    $uuid: String!
    $data: String!
    $alt: String!
    $type: String!
  ) {
    addImageToGallery(
      uuid: $uuid
      image: { data: $data, alt: $alt, type: $type }
    ) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_IMAGE_FROM_GALLERY = gql`
  mutation RemoveImage($uuid: String!) {
    removeImage(uuid: $uuid)
  }
`;

export const UPDATE_IMAGE_IN_GALLERY = gql`
  mutation UpdateImage(
    $uuid: String!
    $data: String!
    $alt: String!
    $type: String!
  ) {
    updateImage(image: { uuid: $uuid, data: $data, type: $type, alt: $alt }) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_RESTAURANT_INFORMATION = gql`
  mutation UpdateRestaurantInformation(
    $uuid: String!
    $cityName: String!
    $cityLocation: String!
    $instagram: String!
    $arrivalPolicy: String!
    $generalPolicy: String!
    $cancellationPolicy: String!
    $contactEmail: String!
    $contactName: String!
    $contactPhone: String!
    $cuisine: [String]!
    $description: String!
    $locationLatitude: Float!
    $locationLongitude: Float!
    $name: String!
    $country: String!
    $notes: String!
    $seatCount: Int!
    $website: String!
  ) {
    updateRestaurantInformation(
      uuid: $uuid
      properties: {
        cityName: $cityName
        cityLocation: $cityLocation
        arrivalPolicy: $arrivalPolicy
        cancellationPolicy: $cancellationPolicy
        contactEmail: $contactEmail
        contactName: $contactName
        contactPhone: $contactPhone
        cuisine: $cuisine
        instagram: $instagram
        description: $description
        generalPolicy: $generalPolicy
        locationLatitude: $locationLatitude
        locationLongitude: $locationLongitude
        name: $name
        notes: $notes
        country: $country
        seatCount: $seatCount
        website: $website
      }
    ) {
      uuid
      name
      notes
      seatCount
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      city {
        name
        location
        country
      }
      cuisine
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
    }
  }
`;

export const SET_RESY_TAGS = gql`
  mutation SetResyTags($tags: [String]!) {
    setResyTags(tags: $tags) {
      name
    }
  }
`;

export const ACTIVATE_RESY = gql`
  mutation ActivateResy($uuid: String!, $active: Boolean!) {
    activateResy(uuid: $uuid, active: $active)
  }
`;

export const SET_RESY_VENUE = gql`
  mutation SetResyVenue($uuid: String!, $id: String!) {
    setResyVenue(uuid: $uuid, id: $id) {
      id
      name
    }
  }
`;

export const SET_RESY_CREDENTIALS = gql`
  mutation SetResyCredentials(
    $uuid: String!
    $email: String!
    $password: String!
  ) {
    setResyCredentials(uuid: $uuid, email: $email, password: $password)
  }
`;

export const UPDATE_MEMBER_INFORMATION = gql`
  mutation UpdateMemberInformation(
    $uuid: String!
    $allergies: [String]!
    $bio: String!
    $birthdate: String!
    $firstName: String!
    $lastName: String!
    $note: String!
    $restrictions: [String]!
  ) {
    updateMemberInformation(
      uuid: $uuid
      information: {
        allergies: $allergies
        bio: $bio
        birthdate: $birthdate
        firstName: $firstName
        lastName: $lastName
        note: $note
        restrictions: $restrictions
      }
    ) {
      uuid
      membershipId
      allergies
      restrictions
      note
      bio
      joinDate
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      favouriteRestaurants {
        uuid
        name
        seatCount
        cuisine
      }
      createdAt
      updatedAt
    }
  }
`;

export const ADD_CREDITS_TO_MEMBER = gql`
  mutation AddCreditToMember($uuid: String!, $credit: Float!, $note: String!) {
    addCreditToMember(uuid: $uuid, credit: $credit, note: $note)
  }
`;

export const SUBTRACT_CREDITS_FROM_MEMBER = gql`
  mutation SubtractCreditFromMember(
    $uuid: String!
    $credit: Float!
    $note: String!
  ) {
    subtractCreditFromMember(uuid: $uuid, credit: $credit, note: $note)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!) {
    updatePassword(password: $password)
  }
`;

export const ADD_MEMBER_INFORMATION = gql`
  mutation AddMemberInformation(
    $uuid: String!
    $allergies: [String]!
    $bio: String!
    $birthdate: String!
    $firstName: String!
    $lastName: String!
    $note: String!
    $restrictions: [String]!
  ) {
    addMemberInformation(
      uuid: $uuid
      information: {
        allergies: $allergies
        bio: $bio
        birthdate: $birthdate
        firstName: $firstName
        lastName: $lastName
        note: $note
        restrictions: $restrictions
      }
    ) {
      uuid
      membershipId
      allergies
      restrictions
      note
      bio
      joinDate
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      favouriteRestaurants {
        uuid
        name
        seatCount
        cuisine
      }
      createdAt
      updatedAt
    }
  }
`;
