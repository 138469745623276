import moment from "moment";
import { West } from "@mui/icons-material";
import { MouseEvent, ReactNode, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import MemberEdit from "./MemberEdit";
import { AppContext } from "../../context/AppContext";
import MemberProfile from "../../components/common/MemberProfile";
import PageSpinner from "../../components/common/PageSpinner";
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import MemberEditCredits from "./MemberEditCredits";
import MemberHistory from "./MemberHistory";

function CustomTabPanel(props: {
  children: ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

export default function MemberDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { member } = useContext(AppContext);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreditsOpen, setIsCreditsOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const handleShowEdit = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsEditOpen(true);
  };

  const handleShowCredits = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsCreditsOpen(true);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      {member ? (
        <Container maxWidth="lg" className="mt-4">
          <Link to="/members" aria-label={t("members.title")}>
            <West />
          </Link>

          <Paper sx={{ padding: 4, mt: 2 }}>
            <Box className="flex gap-2">
              <MemberProfile
                key={id}
                size="lg"
                member={member}
                subTitle={t(`membership.subscriptions.${member.membership!}`)}
              />

              <Box className="flex-1">
                <Box className="flex gap-2 justify-end">
                  <Button variant={"outlined"} onClick={handleShowEdit}>
                    {t("common.edit")}
                  </Button>
                  <Button variant={"outlined"} onClick={handleShowCredits}>
                    <Add />{" "}
                    {t(
                      member.credits
                        ? "members.manageCredits"
                        : "members.addCredits",
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={4}>
              <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                <Tab label={t("members.tabOverview")} {...a11yProps(0)} />
                <Tab label={t("members.tabActivityLog")} {...a11yProps(1)} />
              </Tabs>
              <Box>
                <CustomTabPanel value={tab} index={0}>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                      <Box>
                        <Typography variant="body2">
                          {t("members.availableCredits")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.credits}
                        </Typography>
                      </Box>
                      <Box className="mt-8">
                        <Typography variant="body2">
                          {t("members.birthday")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {moment(member.birthDate).format("MMM, DD YYYY")}
                        </Typography>
                      </Box>
                      <Box className="mt-8">
                        <Typography variant="body2">
                          {t("members.allergies")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.allergies}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Box>
                        <Typography variant="body2">
                          {t("members.bio")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.bio}
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.favoriteLiqour")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.favoriteLiqour ?? "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.favoriteWine")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.favoriteWine ?? "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.preference")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preference ?? "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.preferredDiningTime")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preferredDiningTime ?? "-"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography variant="h6" mt={4} mb={2}>
                      {t("members.contactInfo")}
                    </Typography>
                    <Grid container>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.address")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.address}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.zip} {member.city}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.email")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.email}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.phone")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.phone}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                  <MemberHistory />
                </CustomTabPanel>
              </Box>
            </Box>
          </Paper>
          {isEditOpen && member && (
            <MemberEdit
              isOpen={isEditOpen}
              member={member}
              setIsOpen={setIsEditOpen}
            />
          )}
          {isCreditsOpen && member && (
            <MemberEditCredits
              isOpen={isCreditsOpen}
              member={member}
              setIsOpen={setIsCreditsOpen}
            />
          )}
        </Container>
      ) : (
        <PageSpinner />
      )}
    </div>
  );
}
