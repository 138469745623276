import { createContext, Dispatch, SetStateAction } from "react";
import { UIMode } from "../constants";
import { Gallery, MenuCategory } from "../types";
import { Member } from "../types/Member";
import { Restaurant } from "../types/Restaurant";

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface SnackBarMessageProps {
  message: string;
  severity: "success" | "error" | "info" | "warning";
}

interface Context {
  currentUser?: User;
  member?: Member;
  members?: Member[];
  uiMode?: UIMode;
  restaurant?: Restaurant;
  categories?: MenuCategory[];
  gallery?: Gallery;
  resyValid?: boolean;
  setResyValid?: Dispatch<SetStateAction<boolean>>;
  setIsGlobalLoading?: Dispatch<SetStateAction<boolean>>;
  isGlobalLoading?: boolean;
  setCategories?: Dispatch<SetStateAction<MenuCategory[] | undefined>>;
  setCurrentUser?: Dispatch<SetStateAction<User | undefined>>;
  setMember?: Dispatch<SetStateAction<Member | undefined>>;
  setMembers?: Dispatch<SetStateAction<Member[] | undefined>>;
  setRestaurant?: Dispatch<SetStateAction<Restaurant | undefined>>;
  setUiMode?: Dispatch<SetStateAction<UIMode>>;
  setGallery?: Dispatch<SetStateAction<Gallery | undefined>>;
  setSnackBarMessageProps?: Dispatch<
    SetStateAction<SnackBarMessageProps | undefined>
  >;
}

export const AppContext = createContext<Context>({
  uiMode: UIMode.Dark,
});
