import { useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Gallery, Menu, Restaurant } from "@/app/types";
import { GET_GALLERY, GET_MENU, GET_RESY_VALID } from "../../graphql";
import PageSpinner from "../common/PageSpinner";
import { AppContext } from "../../context/AppContext";
import { Severity } from "../../constants";
import NoData from "../common/NoData";

export function AppData({ uuid }: { uuid: string }) {
  const { t } = useTranslation();
  const {
    restaurant,
    categories,
    setCategories,
    setRestaurant,
    gallery,
    setGallery,
    setResyValid,
    resyValid,
    setSnackBarMessageProps,
    setIsGlobalLoading,
  } = useContext(AppContext);
  // const {
  //   loading,
  //   data: restaurantData,
  //   error: restaurantDataError,
  // } = useQuery<{
  //   restaurant: Restaurant;
  // }>(GET_RESTAURANT, {
  //   variables: { uuid },
  // fetchPolicy: 'network-only'
  // });
  const {
    loading,
    data: restaurantData,
    error: restaurantDataError,
  } = { loading: false } as {
    loading: Boolean;
    data?: { restaurant: Restaurant };
    error?: Error;
  };

  const {
    data: galleryData,
    error: galleryDataError,
    loading: galleryLoading,
  } = useQuery<{
    gallery: Gallery;
  }>(GET_GALLERY, {
    variables: { uuid },
    pollInterval: 60000,
    fetchPolicy: "network-only",
  });

  const {
    data: menuData,
    error: menuDataError,
    loading: menuLoading,
  } = useQuery<{ menu: Menu }>(GET_MENU, {
    variables: { uuid },
    pollInterval: 60000,
    fetchPolicy: "network-only",
  });

  const {
    data: resyValidData,
    loading: resyValidLoading,
    error: resyValidError,
  } = useQuery<{
    resyValid: boolean;
  }>(GET_RESY_VALID, {
    variables: { uuid },
    pollInterval: 60000,
  });

  useEffect(() => {
    if (resyValidData && !resyValidLoading && resyValid !== undefined) {
      setResyValid!(resyValidData.resyValid);
    }
  }, [
    resyValidData,
    setResyValid,
    resyValidLoading,
    setSnackBarMessageProps,
    resyValid,
  ]);

  useEffect(
    () =>
      setIsGlobalLoading?.(galleryLoading || menuLoading || resyValidLoading),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [galleryLoading, menuLoading, resyValidLoading],
  );

  useEffect(() => {
    if (restaurantData?.restaurant && !restaurant) {
      setRestaurant?.(restaurantData.restaurant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantData, restaurant]);

  useEffect(() => {
    if (menuData?.menu?.categories && !categories) {
      setCategories?.(menuData.menu.categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData, categories]);

  useEffect(() => {
    if (galleryData?.gallery && !gallery) {
      setGallery?.(galleryData.gallery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryData, gallery]);

  useEffect(() => {
    if (resyValidError) {
      console.error(resyValidError);
      setSnackBarMessageProps?.({
        message: `${t("common.error")}→ Resy`,
        severity: Severity.Error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resyValidError]);

  useEffect(() => {
    if (restaurantDataError) {
      console.error(restaurantDataError);
      setSnackBarMessageProps?.({
        message: `${t("common.error")}→ Restaurant`,
        severity: Severity.Error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantDataError]);

  useEffect(() => {
    if (galleryDataError) {
      console.error(galleryDataError);
      setSnackBarMessageProps?.({
        message: `${t("common.error")}→ Gallery`,
        severity: Severity.Error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryDataError]);

  useEffect(() => {
    if (menuDataError) {
      console.error(menuDataError);
      setSnackBarMessageProps?.({
        message: `${t("common.error")}→ Menu`,
        severity: Severity.Error,
      });
    }
  }, [menuDataError, setSnackBarMessageProps, t]);

  const lastError = restaurantDataError || galleryDataError || menuDataError;
  if (loading) {
    return <PageSpinner />;
  } else if (lastError) {
    return <NoData title={t("common.error")} message={lastError.message} />;
  }

  return null;
}
