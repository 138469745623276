import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { t } from "i18next";
import Paper from "@mui/material/Paper";

import TableRow from "@mui/material/TableRow";
import {
  Box,
  Unstable_Grid2 as Grid,
  TableSortLabel,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchInput from "../../components/common/SearchInput";
import NoData from "../../components/common/NoData";
import moment from "moment";
import { HistoryEvent, HistoryStatus } from "../../constants";
import { History } from "@/app/types";

interface Column {
  id: "date" | "event" | "status" | "note" | "value" | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value?: number | string) => string;
}

const columns: readonly Column[] = [
  { id: "date", label: t("history.date"), minWidth: 170 },
  {
    id: "event",
    label: t("history.event"),
    minWidth: 100,
  },
  {
    id: "status",
    label: t("history.status"),
    minWidth: 100,
  },
  {
    id: "note",
    label: t("history.note"),
    minWidth: 70,
  },
  {
    id: "value",
    label: t("history.value"),
    minWidth: 70,
  },
  {
    id: "actions",
    label: "",
    align: "right",
    minWidth: 200,
  },
];

function createData(
  date: Date,
  status: HistoryStatus,
  value: string,
  event: HistoryEvent,
  note?: string,
): History {
  return {
    id: Math.round(Math.random() * 10000000000),
    date: moment(date).format("MMM DD, YYYY"),
    value,
    status,
    event,
    note,
  };
}

const rows = [
  createData(
    moment().subtract(2, "weeks").toDate(),
    HistoryStatus.Completed,
    "100",
    HistoryEvent.Credits,
    "Monthly bonus",
  ),
  createData(
    moment().subtract(2, "weeks").toDate(),
    HistoryStatus.Completed,
    "-200",
    HistoryEvent.Credits,
    "Employer bonus",
  ),
  createData(
    moment().subtract(2, "weeks").toDate(),
    HistoryStatus.Completed,
    "50",
    HistoryEvent.Credits,
    "Membership cash back",
  ),
];

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  DATE = "date",
  STATUS = "status",
  EVENT = "event",
}

function descendingComparator(a: History, b: History, orderBy: RowFields) {
  if (b[orderBy]! < a[orderBy]!) {
    return -1;
  }

  if (b[orderBy]! > a[orderBy]!) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: History, b: History) => descendingComparator(a, b, orderBy)
    : (a: History, b: History) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: History[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function MemberHistory() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const visibleRows = (
    useMemo(
      () =>
        stableSort(rows, getComparator(order, orderBy!)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rowsPerPage],
    ) as History[]
  ).filter((row) => {
    return (
      !searchText ||
      new RegExp(searchText, "ig").test(
        `${t(`history.event-${row.event}`)} ${row.note} ${row.value} ${row.id} ${row.date} ${t(`history.status-${row.status}`)}`,
      )
    );
  });

  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : Order.ASC}
                    onClick={createSortHandler(column.id as RowFields)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === Order.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow hover role="button" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    switch (column.id) {
                      case RowFields.EVENT:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {t(`history.event-${row[column.id]}`)}
                          </TableCell>
                        );
                      case RowFields.STATUS:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {t(`history.status-${row[column.id]}`)}
                          </TableCell>
                        );
                      case "actions":
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                          ></TableCell>
                        );
                      default:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof row[column.id] === "number"
                              ? column.format(row[column.id])
                              : row[column.id]}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        translate="yes"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

  return (
    <div>
      <Box>
        <Grid container alignItems="center">
          <Grid sx={{ mb: 1 }} xs={12} md={9}></Grid>
          <Grid xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("common.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {visibleRows.length ? (
          tableContent
        ) : (
          <NoData
            title={t("members.noDataTitle")}
            message={t("members.noDataText")}
          />
        )}
      </Box>
    </div>
  );
}
