import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Container, Typography } from "@mui/material";
// import Notifications from "../../components/shared/Notifications";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";
import { West } from "@mui/icons-material";

export default function AccountHeader() {
  const { t } = useTranslation();
  const { restaurant } = useContext(AppContext);
  const { pathname } = window.location;
  const menuItems = [
    {
      title: "account.profileTitle",
      to: getRestaurantRoutePrefix(restaurant, ""),
    },
    {
      title: "account.menuTitle",
      to: getRestaurantRoutePrefix(restaurant, "menu"),
    },
    {
      title: "account.reservationsTitle",
      to: getRestaurantRoutePrefix(restaurant, "reservations"),
    },
    {
      title: "account.menuKeys",
      to: getRestaurantRoutePrefix(restaurant, "keys"),
    },
  ];

  return (
    <div className="mb-10">
      <Container maxWidth="xl">
        <Box className="flex gap-2 my-4">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.to}>
              <Typography
                variant="body1"
                fontWeight={pathname === item.to ? 700 : 300}
              >
                {t(item.title)}
              </Typography>
            </Link>
          ))}
        </Box>
        {!menuItems.some((i) => i.to === pathname) && (
          <Link
            to={getRestaurantRoutePrefix(restaurant, "")}
            aria-label={t("account.profileTitle")}
          >
            <West />
          </Link>
        )}
      </Container>
      {/* <Notifications restaurant={restaurant} /> */}
    </div>
  );
}
