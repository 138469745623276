import moment, { weekdays } from "moment";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Add, Delete } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { SyntheticEvent, useContext, useRef, useState } from "react";

import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";

import ConfirmModal from "../../components/common/ConfirmModal";
import { TableSizes, TableTypes } from "../../constants";
const CustomTab: any = styled((props) => <Tab {...props} />)(() => ({
  minWidth: 0,
}));

const CustomTabs: any = styled((props) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-scroller": {
    overflowX: "scroll !important",
    marginRight: "80px",
  },
  minWidth: 0,
}));

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
interface PeriodItem {
  id: number;
  dayOfWeek: number;
  time: Date;
  tableTypes: TableTypes[];
  keyValue: number;
}

interface Period {
  id: string;
  period: moment.Moment;
  title: string;
}

type PeriodItemKey = keyof PeriodItem;
const tableHeaders: { key: PeriodItemKey; title: string }[] = [
  {
    key: "dayOfWeek",
    title: "keyAllocation.dayOfWeek",
  },
  {
    key: "time",
    title: "keyAllocation.time",
  },
  {
    key: "tableTypes",
    title: "keyAllocation.tableTypes",
  },
  {
    key: "keyValue",
    title: "keyAllocation.keyValue",
  },
];

const tableTypes = Object.values(TableTypes);

type KeyAllocationTable = {
  [period: string]: { [tableSize: string]: PeriodItem[] };
};
function generatePeriodItems(periods: Period[]): KeyAllocationTable {
  const periodItems: KeyAllocationTable = {};
  const items: PeriodItem[] = [];
  for (const period of periods) {
    periodItems[period.id] = {};
    for (const tableSize of Object.values(TableSizes)) {
      periodItems[period.id][tableSize] = items;
    }
  }

  return periodItems;
}

export default function KeysAllocation() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { restaurant } = useContext(AppContext);
  const inputRef = useRef(null);
  const Weekdays = weekdays();

  const weekDayOptions = Weekdays.map((i, weekIndex) => (
    <MenuItem value={weekIndex} key={i}>
      {i}
    </MenuItem>
  ));
  const [periods, setPeriods] = useState<Period[]>([
    {
      id: moment().format("MM-YYYY"),
      period: moment(),
      title: moment().format("MMM, YYYY"),
    },
    {
      id: moment().add(1, "month").format("MM-YYYY"),
      period: moment().add(1, "month"),
      title: moment().add(1, "month").format("MMM, YYYY"),
    },
    {
      id: moment().add(2, "months").format("MM-YYYY"),
      period: moment().add(2, "months"),
      title: moment().add(2, "months").format("MMM, YYYY"),
    },
    {
      id: moment().add(3, "months").format("MM-YYYY"),
      period: moment().add(3, "months"),
      title: moment().add(3, "months").format("MMM, YYYY"),
    },
    {
      id: moment().add(4, "months").format("MM-YYYY"),
      period: moment().add(4, "months"),
      title: moment().add(4, "months").format("MMM, YYYY"),
    },
    {
      id: moment().add(5, "months").format("MM-YYYY"),
      period: moment().add(5, "months"),
      title: moment().add(5, "months").format("MMM, YYYY"),
    },
  ]);

  const [periodItems, setPeriodItems] = useState<KeyAllocationTable>(
    generatePeriodItems(periods),
  );

  const handlePeriodItemChange = (
    value: string | number | TableTypes[] | Date,
    tabIndex: number,
    tableSizeId: string,
    index: number,
    key: PeriodItemKey,
  ) => {
    if (key === "id") {
      return;
    }
    const { id: periodId } = periods[tabIndex];
    setPeriodItems((periodItems) => {
      return {
        ...periodItems,
        [periodId]: {
          ...periodItems[periodId],
          [tableSizeId]: periodItems[periodId][tableSizeId].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [key]: value,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  function getRow({
    key,
    periodIndex,
    data,
    tabIndex,
    tableSize,
  }: {
    key: PeriodItemKey;
    tabIndex: number;
    periodIndex: number;
    tableSize: string;
    data: PeriodItem;
  }) {
    const periodId = periods[tabIndex].id;
    switch (key) {
      case "dayOfWeek":
        return (
          <FormControl fullWidth>
            <Select
              ref={
                periodIndex + 1 === periodItems[periodId][tableSize]?.length
                  ? inputRef
                  : null
              }
              id={`${key}-${periodIndex}-input`}
              value={data.dayOfWeek}
              onChange={(e) =>
                handlePeriodItemChange(
                  e.target.value,
                  tabIndex,
                  tableSize,
                  periodIndex,
                  key,
                )
              }
            >
              {weekDayOptions}
            </Select>
          </FormControl>
        );
      case "time":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimeField
              onChange={(time) =>
                handlePeriodItemChange(
                  time?.toDate() || new Date(),
                  tabIndex,
                  tableSize,
                  periodIndex,
                  key,
                )
              }
              value={moment(data.time)}
            />
          </LocalizationProvider>
        );
      case "tableTypes":
        return (
          <Autocomplete
            multiple
            options={tableTypes}
            getOptionLabel={(option) => t(`keyAllocation.tableTypes-${option}`)}
            defaultValue={[tableTypes.at(-1)!]}
            filterSelectedOptions
            onChange={(e, value) =>
              handlePeriodItemChange(
                value,
                tabIndex,
                tableSize,
                periodIndex,
                key,
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t(`keyAllocation.tableTypesPlaceholder`)}
              />
            )}
          />
        );
      case "keyValue":
        return (
          <InputBase
            id={`${key}-${periodIndex}-input`}
            margin="none"
            type="number"
            defaultValue={data.keyValue}
            sx={{ p: 0, m: 0 }}
            fullWidth
            placeholder={t(`keyAllocation.keyValuePlaceholder`)}
            onChange={(e) =>
              handlePeriodItemChange(
                e.target.value,
                tabIndex,
                tableSize,
                periodIndex,
                key,
              )
            }
          />
        );
      default:
        return <div></div>;
    }
  }

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function handleRemovePeriodItem(
    tabIndex: number,
    tableSizeId: string,
    index: number,
  ) {
    const { id: periodId } = periods[tabIndex];
    setPeriodItems((periodItems) => {
      return {
        ...periodItems,
        [periodId]: {
          ...periodItems[periodId],
          [tableSizeId]: periodItems[periodId][tableSizeId].filter(
            (_, idx) => idx !== index,
          ),
        },
      };
    });
  }

  function addPeriodItem(tabIndex: number, tableSizeId: string) {
    const { id: periodId } = periods[tabIndex];
    periodItems[periodId] = periodItems[periodId] ?? {};
    periodItems[periodId][tableSizeId] =
      periodItems[periodId][tableSizeId] ?? [];
    const lastPeriodItem = periodItems[periodId][tableSizeId].at(-1);
    const dayOfWeek = (lastPeriodItem?.dayOfWeek ?? -1) + 1;
    const newPeriodItem: PeriodItem = {
      id: (lastPeriodItem?.id ?? 0) + 1,
      dayOfWeek: Weekdays[dayOfWeek] ? dayOfWeek : 0,
      time: new Date(),
      tableTypes: [],
      keyValue: 10,
    };

    setPeriodItems((periodItems) => {
      return {
        ...periodItems,
        [periodId]: {
          ...periodItems[periodId],
          [tableSizeId]: [...periodItems[periodId][tableSizeId], newPeriodItem],
        },
      };
    });

    setTimeout(() => {
      (inputRef?.current as any)?.childNodes?.[0]?.focus?.();
    }, 250);
  }

  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleDeleteTab = (tab: number) => {
    setPeriods(periods.filter((_, index) => index !== tab));
    setTab(tab > 0 ? tab - 1 : 0);
    setIsDeleteModalOpen(false);
  };

  const appendPeriod = (e: SyntheticEvent) => {
    const period = periods.at(-1)!.period.add(1, "month");
    setPeriods([
      ...periods,
      {
        id: period.format("MM-YYYY"),
        period,
        title: period.format("MMM, YYYY"),
      },
    ]);
    setPeriodItems(
      generatePeriodItems([
        ...periods,
        {
          id: period.format("MM-YYYY"),
          period,
          title: period.format("MMM, YYYY"),
        },
      ]),
    );
    handleChange(e, periods.length);
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Typography variant="h6">{t("keyAllocation.title")}</Typography>
        {periods.length > 0 && (
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            position="relative"
          >
            <CustomTabs
              value={tab}
              onChange={handleChange}
              visibleScrollbar
              aria-label={t("keyAllocation.title")}
              sx={{ overflowX: "scroll" }}
            >
              {periods?.map?.((period, index) => (
                <CustomTab
                  label={period.title}
                  key={index}
                  {...tabProps(index)}
                />
              ))}
            </CustomTabs>
            <IconButton
              sx={{ position: "absolute", right: 0, top: 0 }}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Delete />
            </IconButton>
            <IconButton
              sx={{ position: "absolute", right: 40, top: 0 }}
              onClick={appendPeriod}
            >
              <Add />
            </IconButton>
          </Box>
        )}
        {periods.length > 0 && (
          <CustomTabPanel value={tab} index={tab}>
            <TableContainer>
              <Table aria-label={periods[tab].title}>
                <TableHead>
                  <TableRow>
                    {tableHeaders?.map?.((header) => (
                      <TableCell key={header.key}>{t(header.title)}</TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>

                {Object.values(TableSizes).map((tableSize) => (
                  <TableBody key={tableSize}>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography variant="h6">
                          {t(`keyAllocation.tableSizes-${tableSize}`)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {periodItems[periods[tab].id][tableSize]?.map?.(
                      (row, index) => (
                        <TableRow key={index}>
                          {tableHeaders.map((header, periodIndex) => (
                            <TableCell
                              key={`${header.key}-${tableSize}-cell`}
                              scope="row"
                            >
                              {getRow({
                                key: header.key,
                                tableSize,
                                periodIndex,
                                tabIndex: tab,
                                data: row,
                              })}
                            </TableCell>
                          ))}
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                handleRemovePeriodItem(tab, tableSize, index)
                              }
                              aria-label={t("common.delete")}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}

                    <TableRow>
                      <TableCell colSpan={5}>
                        <Button
                          startIcon={<Add />}
                          onClick={() => addPeriodItem(tab, tableSize)}
                          type="button"
                          color="secondary"
                        >
                          {t("keyAllocation.addPeriodItem")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </CustomTabPanel>
        )}
        {isDeleteModalOpen && (
          <ConfirmModal
            title={t("keyAllocation.delete")}
            inputIsOpen={isDeleteModalOpen}
            actionTitle={t("common.delete")}
            inputHandleClose={() => setIsDeleteModalOpen(false)}
            message={t("keyAllocation.deleteMessage")}
            handleConfirm={() => handleDeleteTab(tab)}
          />
        )}
      </Container>
    </div>
  );
}
