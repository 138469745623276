import { FormEvent, SetStateAction, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Container,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { Member } from "@/app/types/Member";
import {
  ADD_CREDITS_TO_MEMBER,
  SUBTRACT_CREDITS_FROM_MEMBER,
} from "../../graphql";
import { AppContext } from "../../context/AppContext";
import { Severity } from "../../constants";

export default function MemberEditCredits({
  isOpen,
  setIsOpen,
  member,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  member: Member;
}) {
  const { t } = useTranslation();
  const { setSnackBarMessageProps } = useContext(AppContext);
  const [addCreditsToMember] = useMutation(ADD_CREDITS_TO_MEMBER);
  const [subtractCreditFromMember] = useMutation(SUBTRACT_CREDITS_FROM_MEMBER);
  const [isLoading, setIsLoading] = useState(false);
  const [credit, setCredit] = useState(0);
  const [isAddition, setIsAddition] = useState(true);
  const [note, setNote] = useState("");
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const uuid = member.uuid;
      if (isAddition) {
        await addCreditsToMember({
          variables: {
            uuid,
            credit,
            note,
          },
        });
      } else {
        await subtractCreditFromMember({
          variables: {
            uuid,
            credit,
            note,
          },
        });
      }
      setSnackBarMessageProps?.({
        message: t("common.changesSaved"),
        severity: Severity.Success,
      });
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });

      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const DrawerContent = (
    <Box sx={{ width: 550 }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="body1">{t("members.creditBalance")}</Typography>
          <Typography variant="h4">{member.credits}</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mt={4}>
            <TextField
              id="credits"
              disabled={isLoading}
              onChange={(e) => setCredit(Number(e.target.value))}
              label={t("members.creditAmount")}
              defaultValue={credit}
              type="number"
              variant="outlined"
              fullWidth
              placeholder="10"
              margin="normal"
            />
          </Box>
          <FormControl>
            <RadioGroup
              defaultValue={isAddition ? "add" : "substract"}
              onChange={(e) => setIsAddition(e.target.value === "add")}
              name="add-subtract-credits"
            >
              <div className="flex">
                <FormControlLabel
                  value="add"
                  disabled={isLoading}
                  control={<Radio size="small" disabled={isLoading} />}
                  label={t("members.addCredits")}
                />
                <FormControlLabel
                  value="substract"
                  disabled={isLoading}
                  control={<Radio size="small" disabled={isLoading} />}
                  label={t("members.subtractCredits")}
                />
              </div>
            </RadioGroup>
          </FormControl>

          <Box mt={2}>
            <TextField
              id="note"
              onChange={(e) => setNote(e.target.value)}
              label={t("members.addCreditsNote")}
              type="textarea"
              disabled={credit === 0 || isLoading}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
          </Box>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ my: 4 }}
            disabled={credit === 0 || isLoading}
          >
            {t(isAddition ? "members.addCredits" : "members.subtractCredits")}
          </Button>
        </form>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
