import {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import FilterListIcon from "@mui/icons-material/FilterList";

import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchInput from "../../components/common/SearchInput";
import MemberProfile from "../../components/common/MemberProfile";
import ReservationDetails from "./ReservationDetails";
import { Reservation, ReservationItem } from "@/app/types/Reservation";
import NoData from "../../components/common/NoData";
import DateRangePicker from "./DateRangePicker";
import { GET_RESERVATIONS } from "../../graphql";
import { useQuery } from "@apollo/client";
import AccountHeader from "../account/AccountHeader";
import { AppContext } from "../../context/AppContext";
import { Severity } from "../../constants";

interface Column {
  id: "member" | "date" | "time" | "table" | "guests" | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: Date | number | string) => string;
}

const timelineFiltersEnabled = false;
const columns: readonly Column[] = [
  { id: "member", label: t("reservations.member"), minWidth: 170 },
  {
    id: "date",
    label: "Date",
    minWidth: 100,
  },
  {
    id: "time",
    label: t("reservations.time"),
    minWidth: 100,
  },
  {
    id: "table",
    label: t("reservations.table"),
    minWidth: 70,
  },
  {
    id: "guests",
    label: t("reservations.guests"),
    minWidth: 50,
  },
  {
    id: "actions",
    label: "",
    minWidth: 200,
    align: "right",
  },
];

enum DateFilter {
  NEXT_7_DAYS = "next-7-days",
  LAST_30_DAYS = "last-30-days",
  THIS_YEAR = "this-year",
  LAST_YEAR = "last-year",
}

const dateFilters = Object.values(DateFilter);

enum TimelineFilter {
  ALL = "all",
  UPCOMING = "upcoming",
  PREVIOUS = "previous",
}

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  MEMBER = "member",
  DATE = "date",
  TIME = "time",
  TABLE = "table",
  GUESTS = "guests",
}

const timeLineFilters = Object.values(TimelineFilter);
function descendingComparator(
  a: ReservationItem,
  b: ReservationItem,
  orderBy: RowFields,
) {
  let comparatorA: string | number | Date = a.date.start;
  let comparatorB: string | number | Date = b.date.start;
  switch (orderBy) {
    case RowFields.MEMBER:
      comparatorA = a.member.preferredName;
      comparatorB = b.member.preferredName;
      break;
    case RowFields.DATE:
    case RowFields.TIME:
      comparatorA = a.date.start;
      comparatorB = b.date.start;
      break;
    case RowFields.TABLE:
      comparatorA = a.table.label;
      comparatorB = b.table.label;
      break;
    case RowFields.GUESTS:
      comparatorA = a.table.maxPartySize;
      comparatorB = b.table.maxPartySize;
      break;
  }

  if (comparatorB < comparatorA) {
    return -1;
  }
  if (comparatorB > comparatorA) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: ReservationItem, b: ReservationItem) =>
        descendingComparator(a, b, orderBy)
    : (a: ReservationItem, b: ReservationItem) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(array: ReservationItem[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Reservations() {
  const [page, setPage] = useState(0);
  const { restaurant, setSnackBarMessageProps } = useContext(AppContext);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [timelineFilter, setTimelineFilter] = useState(TimelineFilter.ALL);
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState<moment.Moment[]>([]);
  const [searchText, setSearchText] = useState("");
  const [reservations, setReservations] = useState<Reservation | undefined>();
  const [selectedReservation, setSelectedReservation] =
    useState<ReservationItem | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] =
    useState<DateFilter | null>(null);
  const dateFilterOpen = Boolean(anchorEl);
  const {
    data: reservationsData,
    loading,
    refetch,
  } = useQuery<{ reservations: Reservation }>(GET_RESERVATIONS, {
    variables: { uuid: restaurant?.uuid },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (reservationsData?.reservations && !reservations) {
      setReservations!(reservationsData.reservations);
    }
  }, [reservationsData, setReservations, reservations]);
  const handleShowDateFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const handleDateFilterChanged = async (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    try {
      await refetch({
        uuid: restaurant?.uuid,
        ...getDateFilterResultQuery(newRange, newFilter),
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });

      return error;
    }
  };
  const handleSelectDateFilter = async (filter: DateFilter | null) => {
    setSelectedDateFilter(filter);
    setDateRange([]);
    await handleDateFilterChanged([], filter);
    handleCloseDateFilter();
  };

  const handleShowDateRangePicker = () => {
    setIsDateRangeModalOpen(true);
    handleCloseDateFilter();
  };

  const handleSetDateRange = (dateRange: moment.Moment[]) => {
    setSelectedDateFilter(null);
    setDateRange(dateRange);
    handleDateFilterChanged(dateRange, null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleShowDetail = (row: ReservationItem) => {
    setSelectedReservation(row);
    setIsDetailOpen(true);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const getDateFilterResult = (date: Date) => {
    switch (selectedDateFilter) {
      case DateFilter.NEXT_7_DAYS:
        return (
          moment(date).isAfter(moment().subtract(1, "day")) &&
          moment(date).isBefore(moment().add(7, "days"))
        );
      case DateFilter.LAST_30_DAYS:
        return moment(date).isAfter(moment().subtract(30, "days"));
      case DateFilter.THIS_YEAR:
        return moment(date).isAfter(moment().startOf("year"));
      case DateFilter.LAST_YEAR:
        return moment(date).isBefore(moment().startOf("year"));
      default:
        return true;
    }
  };
  const getDateFilterResultQuery = (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    const [from, to] = newRange;
    if (from && to) {
      return {
        from: from.toISOString(),
        to: to.toISOString(),
      };
    }

    switch (newFilter) {
      case DateFilter.NEXT_7_DAYS:
        return {
          from: moment().startOf("day").toISOString(),
          to: moment().add(7, "days").endOf("day").toISOString(),
        };

      case DateFilter.LAST_30_DAYS:
        return {
          from: moment().subtract(30, "days").startOf("day").toISOString(),
          to: moment().endOf("day").toISOString(),
        };

      case DateFilter.THIS_YEAR:
        return {
          from: moment().startOf("year").toISOString(),
          to: moment().endOf("day").toISOString(),
        };

      case DateFilter.LAST_YEAR:
        return {
          from: moment().subtract(1, "year").startOf("year").toISOString(),
          to: moment().subtract(1, "year").endOf("year").toISOString(),
        };

      default:
        return {};
    }
  };

  const visibleRows = (
    useMemo(
      () =>
        stableSort(reservations?.items ?? [], getComparator(order, orderBy!)),
      [order, orderBy, reservations],
    ) as ReservationItem[]
  )
    .filter((row) => {
      switch (timelineFilter) {
        case TimelineFilter.UPCOMING:
          return moment(row.date.start).isAfter(moment());
        case TimelineFilter.PREVIOUS:
          return moment(row.date.start).isBefore(moment());
        default:
          return true;
      }
    })
    .filter((row) => {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        return (
          moment(row.date.start).isSameOrAfter(startDate) &&
          moment(row.date.start).isSameOrBefore(endDate)
        );
      }

      return getDateFilterResult(row.date.start);
    })
    .filter((row) => {
      return (
        row.member.preferredName
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        row.member.email.toLowerCase().includes(searchText.toLowerCase())
      );
    });

  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === "date" ? (
                    <Typography variant="body2">
                      {column.label}
                      <IconButton
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={dateFilterOpen ? "true" : undefined}
                        onClick={handleShowDateFilter}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        sx={{ width: 500 }}
                        anchorEl={anchorEl}
                        open={dateFilterOpen}
                        onClose={handleCloseDateFilter}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {dateFilters.map((filter) => (
                          <MenuItem
                            selected={selectedDateFilter === filter}
                            key={filter}
                            onClick={() => handleSelectDateFilter(filter)}
                          >
                            <Typography variant="body2">
                              {t(`reservations.dateFilter-${filter}`)}
                              {filter === DateFilter.THIS_YEAR &&
                                moment().format(" (YYYY)")}
                              {filter === DateFilter.LAST_YEAR &&
                                moment().subtract(1, "year").format(" (YYYY)")}
                            </Typography>
                          </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem onClick={() => handleShowDateRangePicker()}>
                          <Typography variant="body2">
                            {t("reservations.selectDateRange")}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          disabled={
                            !selectedDateFilter && dateRange.length === 0
                          }
                          onClick={() => handleSelectDateFilter(null)}
                        >
                          <Typography variant="body2">
                            {t(`common.clearAll`)}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Typography>
                  ) : (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : Order.ASC}
                      onClick={createSortHandler(column.id as RowFields)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === Order.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow
                  hover
                  role="button"
                  tabIndex={-1}
                  key={i}
                  onClick={() => handleShowDetail(row)}
                >
                  {columns.map((column) => {
                    switch (column.id) {
                      case RowFields.MEMBER:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <MemberProfile member={row.member} />
                          </TableCell>
                        );
                      case RowFields.TIME:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {moment(row.date.start).format("h:mm a").toString()}
                          </TableCell>
                        );
                      case RowFields.DATE:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Tooltip
                              placement="top-start"
                              title={moment(row.date.start).format(
                                "MMM DD, YYYY",
                              )}
                            >
                              <Typography variant="body2" noWrap>
                                {moment(row.date.start).fromNow()}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        );
                      case RowFields.GUESTS:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.table.maxPartySize}
                          </TableCell>
                        );
                      case "actions":
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => handleShowDetail(row)}
                            >
                              {t("common.view")}
                            </Button>
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            --
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {visibleRows.length === 0 ? (
        <NoData
          topMargin="0"
          title={t("reservations.noDataTitle")}
          message={t("reservations.noDataText")}
        />
      ) : (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          disabled={loading}
          count={reservationsData?.reservations.count ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          translate="yes"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );

  return (
    <div>
      <AccountHeader />
      {selectedReservation && (
        <ReservationDetails
          isOpen={isDetailOpen}
          reservation={selectedReservation}
          setIsOpen={setIsDetailOpen}
        />
      )}
      <Container maxWidth="xl">
        <Grid container className="my-8">
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <Typography variant="h6" noWrap>
              {t("reservations.title")}
            </Typography>
            {timelineFiltersEnabled && (
              <div className="mt-4">
                {timeLineFilters.map((filter) => (
                  <Chip
                    key={filter}
                    variant={timelineFilter === filter ? "outlined" : "filled"}
                    onClick={() => setTimelineFilter(filter)}
                    sx={{ marginRight: 1 }}
                    label={t(`reservations.timeLine-${filter}`)}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("common.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {tableContent}
        <DateRangePicker
          save={handleSetDateRange}
          isOpen={isDateRangeModalOpen}
          handleClose={() => setIsDateRangeModalOpen(false)}
        />
      </Container>
    </div>
  );
}
